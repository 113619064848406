import { RepositoryFactory } from "../../repository/RepositoryFactory";
import axios from "axios";

let streetRepo = RepositoryFactory.get("street");

const state = {
  lng: 0,
  lat: 0,
  street: null,
  city: null,
  fullLoader: false,
  streetNumber: null,
  premise: null
};

const getters = {
  lng: (state) => state.lng,
  lat: (state) => state.lat,
  city: (state) => state.city,
  street: (state) => state.street,
  streetNumber: (state) => state.streetNumber,
  premise: (state) => state.premise,

  fullLoader: (state) => state.fullLoader,
};

const actions = {
  async getLocation({ commit }, payload) {
    try {
      commit("setFullLoader", true);
      const response = await streetRepo.getStreet(payload);
      const street = response.data.street;
      const city = response.data.settlement;
      const streetNumber = response.data.streetNumber;
      const premise = response.data.premise;

      commit("city", city);
      commit("street", street);
      commit("streetNumber", streetNumber);
      commit("premise", premise);

      commit("setFullLoader", false);
      payload.onSuccess();

      console.log(street, "street");
      console.log(city, "city");
      const lng = response.data.results[0].geometry.location.lng;
      commit("lng", lng);
      const lat = response.data.results[0].geometry.location.lat;
      commit("lat", lat);
      // return {
      //   success: true,
      //   city: city,
      //   street: street,
      // };
    } catch (error) {
      payload.onError();
      // commit("city", null);
      // commit("street", null);
      // alert(error)
      commit("setFullLoader", false);
      // alert("getStreet error");
      // alert(error.message,);
      // payload.onError();
      // commit("lat", 0);
      // commit("lng", 0);
      // commit("city", city);
      // commit("street", street);

    }

    try {
      const options = {
        method: "GET",
        url: "https://maps.googleapis.com/maps/api/geocode/json",
        params: {
          address: `${payload.street} ${payload.premise} ${payload.streetNumber} ${payload.house_number} ${payload.zip} , Netherlands `,
          key: "AIzaSyAZ4MO64L0GEOB9TmzfYgeYbGnZGX9AAUc",
        },
      };
      axios.request(options).then(function (response) {
        console.log(response.data);

        const lng = response.data.results[0].geometry.location.lng;
        commit("lng", lng);
        const lat = response.data.results[0].geometry.location.lat;
        commit("lat", lat);
      });
    } catch (error) {
      alert(error);
    }
  },
};

const mutations = {
  lng(state, payload) {
    state.lng = payload;
  },
  lat(state, payload) {
    state.lat = payload;
  },
  city(state, payload) {
    state.city = payload;
  },
  street(state, payload) {
    state.street = payload;
  },
  streetNumber(state, payload) {
    state.streetNumber = payload;
  },
  premise(state, payload) {
    state.premise = payload;
  },
  setFullLoader(state, payload) {
    state.fullLoader = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
