import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Home2 from "../views/HomeV2.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/kunststofkozijnen",
  },
  {
    path: "/kunststofkozijnen",
    name: "Home",
    component: Home,
  },
  {
    path: "/kunststofkozijnenv2",
    name: "Home2",
    component: Home2,
  },
  {
    path: "/Bedanktv2",
    name: "Bedanktv2",
    component: () => import("../views/Bedanktv2.vue"),
  },
  {
    path: "/Bedankt",
    name: "Bedankt",
    component: () => import("../views/Bedankt.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
