<template>
  <div class="">
    <h4 class="font-weight-bold form__text position-relative">
      Ontvang <span class="text_secondary">gratis</span> en vrijblijvend meer
      informatie en superscherpe offertes voor kunststof kozijnen van
      specialisten in jouw regio
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="35"
        class="position-absolute d-md-none d-block"
        viewBox="0 0 110.000000 173.000000"
        preserveAspectRatio="xMidYMid meet"
        style="transform: rotate(30deg); bottom: -50px; right: 15px"
      >
        <g
          transform="translate(0.000000,173.000000) scale(0.100000,-0.100000)"
          fill="#bc0808"
          stroke="none"
        >
          <path
            d="M40 1553 l0 -130 87 -12 c453 -64 734 -527 576 -952 -14 -38 -29 -69 -33 -69 -5 0 -42 20 -83 44 l-75 44 39 -146 c22 -81 47 -177 56 -214 10 -42 22 -68 30 -68 21 0 397 101 406 109 4 4 -27 26 -68 50 -41 23 -75 44 -75 45 0 2 14 33 30 70 54 119 74 223 74 386 0 117 -4 161 -22 226 -45 169 -121 309 -230 425 -173 184 -393 293 -639 315 l-73 7 0 -130z"
          />
        </g>
      </svg>
    </h4>
    <div class="my-4">
      <b-form @submit.prevent="onSubmit">
        <input type="checkbox" class="hidden-checkbox" v-model="bootCheck" />
        <b-row>
          <div class="col-12 col-md-6">
            <b-form-group id="fName" label="Voornaam" label-for="fName">
              <b-form-input
                id="fName"
                :class="
                  this.getErrorResponses.firstname == false ? 'is-invalid' : ''
                "
                v-model="$v.second_form.firstname.$model"
                :state="validateState('firstname')"
                placeholder="bv. Pieter"
                required
                @input="handleFieldInput('firstname')"
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="
                  !$v.second_form.firstname.required ||
                  !getErrorResponses.firstname
                "
              >
                Voornaam niet correct
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group id="lName" label="Achternaam" label-for="lName">
              <b-form-input
                :class="
                  this.getErrorResponses.lastname == false ? 'is-invalid' : ''
                "
                id="lName"
                v-model="$v.second_form.lastname.$model"
                :state="validateState('lastname')"
                @input="handleFieldInput('lastname')"
                placeholder="bv. de Jong"
                required
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="
                  !$v.second_form.lastname.required ||
                  !getErrorResponses.lastname
                "
              >
                Achternaam niet correct
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-row>
        <b-row>
          <div class="col-12 col-md-6">
            <b-form-group id="tel" label="Telefoonnummer" label-for="tel">
              <b-form-input
                :class="
                  this.getErrorResponses.phone_number == false
                    ? 'is-invalid'
                    : ''
                "
                id="tel"
                type="tel"
                v-model="$v.second_form.phone_number.$model"
                :state="validateState('phone_number')"
                @input="handleFieldInput('phone_number')"
                placeholder="bv. 0612345678"
                required
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="
                  !$v.second_form.phone_number.required ||
                  !getErrorResponses.phone_number
                "
              >
                Telefoonnummer niet correct
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <label for="email">E-mailadres</label>
            <b-form-input
              :class="this.getErrorResponses.email == false ? 'is-invalid' : ''"
              v-model="$v.second_form.email.$model"
              :state="validateState('email')"
              @input="handleFieldInput('email')"
              type="email"
              id="email"
              placeholder="bv. piet@dejong.nl"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="!$v.second_form.email.required || !getErrorResponses.email"
            >
              E-mailadres niet correct
            </b-form-invalid-feedback>
          </div>
        </b-row>

        <!--  -->
        <b-form-group
          id="when"
          label="Wanneer wil je de kozijnen geplaatst hebben?"
          label-for="when"
        >
          <b-form-select
            id="when"
            v-model="$v.second_form.when.$model"
            :state="validateState('when')"
            :options="when"
            required
          ></b-form-select>
        </b-form-group>
        <!--  -->
        <b-col lg="12" class="py-4"
          ><b-button
            :disabled="getLoader"
            size="lg"
            type="submit"
            variant="success"
            class="w-100 py-3"
            style="background: #759e62; border: #74a301"
          >
            <b-spinner v-if="getLoader" /><span v-else
              ><b>
                Afronden
                <i
                  class="fas fa-angle-right align-middle ml-1"
                ></i> </b></span></b-button
        ></b-col>
      </b-form>
      <div class="text-center">
        <small class="text-muted font10">
          Door vrijblijvend offertes aan te vragen ga je akkoord met onze
          <span class="cursor-pointer" v-b-modal.Algemene>
            Algemene voorwaarden</span
          ></small
        >
      </div>
    </div>
    <!-- modal -->
    <b-modal
      id="Algemene"
      centered
      title="Algemene voorwaarden"
      ok-only
      size="lg"
      ok-variant="success"
      scrollable
    >
      <div>
        <p>
          Via Offertevergelijker.nl vragen dagelijks vele Nederlanders offertes
          aan voor zijn of haar klus. Nadat je een offerteaanvraag hebt
          doorgestuurd via één van onze formulieren, bekijken wij de aanvraag en
          indien deze akkoord is, zoeken wij maximaal drie bedrijven waarvan wij
          denken dat ze u een goede aanbieding kunnen doen. Het aanvragen van
          offertes is gratis en vrijblijvend.
        </p>
        <h4>Persoonsgegevens</h4>
        <p>
          Om een offerteaanvraag door te kunnen zetten naar relevante partners,
          hebben wij enkele persoonsgegevens van u nodig. Onder persoonsgegevens
          verstaan wij ieder gegeven dat je aan ons doorgeeft, waarmee jij als
          persoon aanwijsbaar bent. Voorbeelden van persoonsgegevens zijn je
          naam en adres.
        </p>
        <p>
          Wij vragen tevens om een telefoonnummer. Deze vragen wij, omdat de
          geselecteerde bedrijven bij jouw aanvraag in sommige gevallen
          aanvullende vragen hebben. Zo kunnen zij de offerte zo op maat
          mogelijk maken en krijgt u het beste overzicht van de mogelijkheden.
        </p>
        <h4>Geen garantie</h4>
        <p>
          Bij Offertevergelijker brengen wij particulieren en bedrijven bij
          elkaar. De rol van Offertevergelijker.nl gaat nooit verder dan het
          faciliteren van vraag en aanbod. Wij geven geen garantie op de
          geleverde diensten of uitgevoerde werkzaamheden. Offertevergelijker.nl
          is dan ook niet aansprakelijk voor eventuele schade door of
          onwenselijk gedrag van het uitvoerende bedrijf. U blijft te allen
          tijde zelf verantwoordelijk voor het vastleggen van goede afspraken
          met het bedrijf dat uw werkzaamheden uitvoert.
        </p>
        <h4>Wijzigingen algemene voorwaarden</h4>
        <p>
          Offertevergelijker heeft het recht om de informatie op deze website
          aan te passen en kan dit doen zonder hiervan enige kennisgeving
          daarvan.
        </p>
        <p>
          Door onze website te bezoeken, ga je automatisch akkoord met deze
          algemene voorwaarden.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, alphaNum, email, numeric } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const alphaName = helpers.regex("alpha", /^[a-zA-Z\s.,'-]{1,30}$/);
const alphaPno = helpers.regex(
  "alpha",
  /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/
);

export default {
  name: "SecondForm",
  mixins: [validationMixin],
  data() {
    return {
      phoneRegex: /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/,
      nameRegex: /^[a-zA-Z\s.,'-]{1,30}$/,
      emailRegex: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/,
      bootCheck: false,
      second_form: {
        when: "null",
        firstname: "",
        lastname: "",
        phone_number: "",
        email: "",
        site_subid: "",
        publisher_id: "",
        site_custom_url:
          "https://offertevergelijker.verbeterhuizen.nl/kunststofkozijnenv2",
        site_custom_name: "offertevergelijker_kunststofkozijnenv2",
      },
      when: [
        { text: "Selecteer wanneer", value: null, disabled: true },
        { text: "In overleg", value: "3796", disabled: false },
        { text: "Binnen 3 maanden", value: "3799", disabled: false },
        { text: "Binnen 3 tot 6 maanden", value: "3802", disabled: false },
      ],
    };
  },
  methods: {
    ...mapActions(["postLead", "closeSecondForm"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        const phoneNumberWithoutPunctuation = this.second_form.phone_number
          .replace(/[\s-]/g, "")
          .replace(/^00(\d+)$/, "$1");
        let form = {
          language: "nl_NL",
          firstname: this.second_form.firstname,
          lastname: this.second_form.lastname,
          email: this.second_form.email,
          phone_number: this.second_form.phone_number,
          zip: this.first_form.zip,
          city: this.city,
          street: this.street,
          house_number: this.first_form.house_number,
          answers: ["3778", this.first_form.dak, this.second_form.when],
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
          site_custom_url:
            "https://offertevergelijker.verbeterhuizen.nl/kunststofkozijnenv2",
          site_custom_name: "offertevergelijker_kunststofkozijnenv2",
        };
        let resp = null;
        if (this.city == null || this.street == null) {
          const { city, street, ...exclude } = form;
          resp = await this.postLead({
            ...exclude,
            bootCheck: this.bootCheck,
            phone_number: phoneNumberWithoutPunctuation,
          });
          // resp = await console.log(exclude);
          if (resp.success == true) {
            this.$router.push("bedanktv2");
          }
          if (
            this.getErrorResponses.zip == false ||
            this.getErrorResponses.housenumber == false
          ) {
            console.log("secondform redirection");
            await this.closeSecondForm();

            return;
          }
        } else {
          resp = await this.postLead({
            ...form,
            bootCheck: this.bootCheck,
            phone_number: phoneNumberWithoutPunctuation,
          });
          // resp = await console.log("form", form);
          if (resp.success == true) {
            this.$router.push("bedanktv2");
          }
        }

        // console.log(this.form, "form");
      }
    },
    // validations
    // validateState(kWh) {
    //   const { $dirty, $error } = this.$v.second_form[kWh];
    //   return $dirty ? !$error : null;
    // },
    validateState(when) {
      const { $dirty, $error } = this.$v.second_form[when];
      return $dirty ? !$error : null;
    },
    validateState(firstname) {
      const { $dirty, $error } = this.$v.second_form[firstname];
      return $dirty ? !$error : null;
    },
    validateState(lastname) {
      const { $dirty, $error } = this.$v.second_form[lastname];
      return $dirty ? !$error : null;
    },
    validateState(phone_number) {
      const { $dirty, $error } = this.$v.second_form[phone_number];
      return $dirty ? !$error : null;
    },
    validateState(email) {
      const { $dirty, $error } = this.$v.second_form[email];
      return $dirty ? !$error : null;
    },
  },
  validations: {
    second_form: {
      // kWh: {
      //   required,
      // },
      when: {
        required,
      },
      firstname: {
        required,
        // alphaNum,
        alphaName,
      },
      lastname: {
        required,
        // alphaNum,
        alphaName,
      },
      phone_number: {
        required,
        // numeric,
        alphaPno,
      },
      email: {
        required,
        email,
      },
    },
  },

  computed: {
    ...mapGetters([
      "getLoader",
      "getErrorResponses",
      "first_form",
      "city",
      "street",
    ]),
    // phoneValidation() {
    //   return (
    //     this.phoneRegex.test(this.second_form.phone_number) ||
    //     this.second_form.phone_number === ""
    //   );
    // },
    // firstNameValidation() {
    //   return (
    //     this.nameRegex.test(this.second_form.firstname) ||
    //     this.second_form.firstname === ""
    //   );
    // },
    // lastNameValidation() {
    //   return (
    //     this.nameRegex.test(this.second_form.lastname) ||
    //     this.second_form.lastname === ""
    //   );
    // },
    // emailValidation() {
    //   return (
    //     this.emailRegex.test(this.second_form.email) ||
    //     this.second_form.email === ""
    //   );
    // },
    // isValidForm() {
    //   return (
    //     this.phoneValidation &&
    //     this.firstNameValidation &&
    //     this.lastNameValidation &&
    //     this.emailValidation
    //   );
    // },
  },
};
</script>

<style>
@media (max-width: 499.98px) {
  .form__text {
    font-size: 20px;
  }
}
.font-weight-bold {
  font-weight: 700 !important;
}
.text_secondary {
  color: #74a301 !important;
}
.font10 {
  font-size: 10px;
}
.bg-secondary {
  background: #74a301 !important;
}
</style>
