<template>
  <div>
    <div
      v-if="fullLoader"
      class="d-flex justify-content-center align-items-center"
      style="height: 100vh"
    >
      <b-spinner class="text-success" />
    </div>
    <b-container v-else class="py-md-5">
      <b-row cols=" py-0 py-md-4">
        <div class="col-12 col-md-6 col-lg-6">
          <a href="#"><img src="../assets/logo.png" class="logo" /></a>
          <h3 class="font-weight-bold m-0">
            Goed nieuws! Jouw woning

            <span class="text-secondary">
              {{ this.street }} {{ this.streetNumber }}{{ this.premise }}</span
            >
            in <span class="text-secondary">{{ this.city }} </span> kan tot wel
            30% subsidie ontvangen.
          </h3>
          <div class="my-2 my-md-4">
            <div>
              <GmapMap
                :options="{
                  zoomControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: true,
                  disableDefaultUi: false,
                }"
                :center="{ lng: this.lng, lat: this.lat }"
                :zoom="16"
                map-type-id="terrain"
                style="width: 100%; height: 350px"
                class="map-height"
              >
                <GmapMarker
                  :position="{
                    lng: this.lng,
                    lat: this.lat,
                  }"
                  :clickable="true"
                  :draggable="true"
                />
              </GmapMap>
            </div>
          </div>
          <h3 class="text-">Bespaar tot 30% in maar 2 minuten!</h3>
        </div>
        <!--  -->
        <div class="col-12 col-md-6">
          <div class="bg-white shadow p-3 p-md-4 banner__form">
            <SecondForm />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SecondForm from "./SecondForm.vue";
export default {
  name: "SecondFormSection",
  components: { SecondForm },
  computed: mapGetters([
    "lng",
    "lat",
    "first_form",
    "street",
    "city",
    "fullLoader",
    "streetNumber",
    "premise",
  ]),
};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
  // h2 {
  //   font-size: 24px;
  // }
  .logo {
    width: 130px;
    margin-top: 10px;
  }
  h3 {
    font-size: 22px;
  }
  .map-height {
    height: 200px !important;
  }
}
@media (max-width: 399.98px) {
  h3 {
    font-size: 18.9px;
  }
  .map-height {
    height: 100px !important;
  }
}
.text-success {
  color: #74a301 !important;
}
.text-secondary {
  color: #74a301 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.banner {
  &__form {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #74a301 !important;
  }
}
</style>
